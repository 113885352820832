import React from 'react';
import './EulaPage.css';

const EulaPage = () => {
    return (
        <div style={{ padding: '1rem' }}>
            <h1 style={{ textAlign: 'center' }}>Kur'an Kütüphanesi</h1>
            <h2>Giriş</h2>
            <p>İşbu metin, <b>Kur'an Kütüphanesi</b> uygulamasının sunmuş olduğu hizmetle alakalı olarak; gizlilik politikası, kullanım tavsiyeleri ve sorumluluk reddi gibi önemli hususlarda aydınlatma sağlamak amacıyla hazırlanmıştır.</p>
            <h2>Gizlilik Politikası</h2>
            <p>Hizmetlerimiz sırasında, kimliklendirme ve iletişim bilgileri, cihazınızın teknik özellikleri, IP adresiniz ve kullanım verileriniz dahil olmak üzere çeşitli bilgiler toplanabilir. Firebase Analytics aracılığıyla, uygulama kullanımınızla ilgili verileri anonim olarak topluyoruz; bu, uygulama performansını izlemek ve kullanıcı deneyimini özelleştirmek için kullanılır.</p>
            <p>Anonim verilere ek olarak, kullanıcının bulut senkronizasyonu özelliğini kullanması halinde, giriş yapılan kullanıcı ile ilişkilendirimiş veriler senkronizasyon özelliğinin çalışabilmesi için Firebase sunucu ve veritabanlarımızda depolanır. Bu veriler, kitap üzerinde alınan notlar gibi, uygulama tarafından senkronize edilen verilerden oluşur. Bunlara ek olarak; kullanıcılarımızın uygulama içi sisteme giriş yapmasıyla; e-posta, isim ve (varsa) profil fotoğrafı bilgileri Yetkilendirme Servisinde depolanır.</p>
            <p>Toplanan veriler, hizmetlerimizi iyileştirmek amacıyla Google ve Firebase gibi servis sağlayıcılarımızla paylaşılabilir. Bu, yasal gereklilikler dahilinde işler ve tüm bilgiler, kullanıcıların gizliliğini koruyacak şekilde yönetilir.</p>
            <h2>Bulut Senkronizasyonu Hakkında</h2>
            <p>Bulut senkronizasyonu özelliği sayesinde, verilerinizi bulutta yedekleyebilir ve gerektiğinde birden fazla cihazda senkronize edebilirsiniz. Bu sayede hem veri kaybını önlemek kolaylaşır, hem de verilerinize farklı cihazlardan da erişme imkanı elde edersiniz.</p>
            <p>Bu özelliğin kullanımında bazı hususlara dikkat etmek muhtemel veri kayıplarının önlenmesine yardımcı olur. Mümkün olduğu kadar sık senkronizasyon yapılması ve aynı hesap ile çok fazla cihazda aynı anda işlem yapılmaması uygun olur. Ayrıca kullandığınız cihazın saatinin yeterince doğru olması daha isabetli bir senkronizasyon için uygun olacaktır.</p>
            <p>Diğer taraftan, dilediğinizde uygulama içerisindeki ilgili özelliği kullanarak, yerel cihazınızdaki verilerinizi haricî bir dosyaya kaydetmek suretiyle, buluttan ve buluttaki mevcut verilerinizden bağımsız olarak yedekleyebilirsiniz.</p>
            <h2>Sorumluluk Reddi Beyanı</h2>
            <p>Uygulama geliştiricileri, uygulama içeriğinin doğruluğu, güncelliği veya eksiksizliği konusunda hiçbir garanti vermez ve herhangi bir hata, eksiklik veya yanlış yorumdan dolayı ortaya çıkabilecek maddi ve manevi herhangi bir zarardan sorumlu tutulamaz. Uygulamada yer alan kitaplar, eserler ve makalelerin içeriğinin doğruluğunu veya hatasız olduğunu garanti etmez. Bu içerikler, çeşitli kaynaklardan (ve kimi zaman bizzat kullanıcılardan) toplanmış olup, herhangi bir konuda kesin veya eksiksiz bilgi sağlama amacı taşımaz.</p>
            <p>Uygulamamız; içerdiği kitaplar, eserler ve makaleler de dahil olmak üzere barındırdığı tüm içerik ve özellikler ile beraber “OLDUĞU GİBİ” ve “MÜMKÜN OLDUĞU” temelde “EN İYİ ÇABA” ilkesi çerçevesinde sunulmuştur. Bu içeriklerin ve özelliklerin doğruluğu, hatasızlığı, eksiksizliği konusunda sarih veya zımni hiçbir garanti verilmemektedir. </p>
            <h2>İletişim</h2>
            <p>Sorularınız için <a href="mailto:islamlibrary.app@gmail.com">islamlibrary.app@gmail.com</a> adresinden
                bizimle iletişime geçebilirsiniz.</p>
        </div>
    );
};

export default EulaPage;
