import React, {useEffect} from 'react';
import './PrivacyPage.css';

// TODO: remove this page
const PrivacyPage = () => {
    useEffect(() => {
        window.location.href = '/eula';
    }, []);

    return (
        <p>Kur'an Kütüphanesi</p>
    );
};

export default PrivacyPage;
