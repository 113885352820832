import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import PrivacyPage from "./Pages/PrivacyPage";
import HomePage from "./Pages/HomePage";
import DownloadPage from "./Pages/DownloadPage";
import SupportPage from "./Pages/SupportPage";
import EulaPage from "./Pages/EulaPage";
import AccountDeletionPage from "./Pages/AccountDeletionPage";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/privacy" element={<PrivacyPage/>}/>
                <Route path="/eula" element={<EulaPage/>}/>
                <Route path="/download" element={<DownloadPage/>}/>
                <Route path="/support" element={<SupportPage/>}/>
                <Route path="/account-deletion" element={<AccountDeletionPage/>}/>
            </Routes>
        </Router>
    );
}

export default App;
