import React from 'react';
import './AccountDeletionPage.css';

const AccountDeletionPage = () => {
    return (
        <div style={{padding: '1rem'}}>
            <h2 style={{textAlign: 'center'}}>Hesap Silme Talebi</h2>

            <p style={{textAlign: 'center'}}>Merhaba! Kur'an Kütüphanesi uygulaması olarak, kullanıcılarımızın
                gizliliğini ve veri güvenliğini
                önemsiyoruz.</p>
            <p style={{textAlign: 'center'}}>
                Bu nedenle, kullanıcılarımıza hesaplarını silme imkanı sunmaktayız.
            </p>
            <p style={{textAlign: 'center'}}>
                <a
                    href="mailto:islamlibrary.app@gmail.com">islamlibrary.app@gmail.com</a> adresi üzerinden, hesabınızı
                ve hesabınıza bağlı verileri silme talebinde bulunabilirsiniz.
            </p>
            <p style={{textAlign: 'center'}}>
                Teşekkür ederiz!
            </p>
        </div>
    );
};

export default AccountDeletionPage;
