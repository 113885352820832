import React from 'react';
import './SupportPage.css';

const SupportPage = () => {
    return (
        // <div style={{maxWidth: '600px', margin: 'auto'}}>
        //     <h1 style={{textAlign: 'center'}}>Kur’an Kütüphanesi’ni Destekleyin!</h1>
        //     <div style={{textAlign: 'center', marginLeft: '10px', marginRight: '10px', marginBottom: '32px'}}>
        //         <p>
        //             Merhaba! Uygulamamızı geliştirmek ve sizlere daha iyi hizmet sunabilmek için desteğinize ihtiyacımız
        //             var. Sunucu hizmetleri ve yeni özellikler, maliyetlerimizi artırıyor.
        //             Yapacağınız bağışlar, bu hizmetin devamlılığı için çok önemli. Lütfen desteğinizi esirgemeyiniz.
        //         </p>
        //     </div>
        //
        //     <a href="https://www.buymeacoffee.com/kurankutuphanesi" target="_blank" rel="noopener noreferrer">
        //         <img
        //             style={{width: '100%', maxWidth: '400px', display: 'block', margin: 'auto'}}
        //             src={'/static-resources/images/support_us.png'}
        //             alt="Bizi Destekleyin"
        //             className="SupportPage-supportButton"/>
        //     </a>
        // </div>

        <div style={{maxWidth: '600px', margin: 'auto'}}>
            <h1 style={{textAlign: 'center'}}>Teşekkür Ederiz</h1>
            <div style={{textAlign: 'center', marginLeft: '10px', marginRight: '10px', marginBottom: '32px'}}>
                <p>
                    Şu anda maddi destek kabul edemiyoruz. Teşekkür ederiz! 😊
                </p>
            </div>
        </div>
    );

};

export default SupportPage;
