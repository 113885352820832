import React, {useEffect} from 'react';
import "./HomePage.css"

const HomePage = () => {
    useEffect(() => {
        window.location.href = '/download';
    }, []);

    return (
        <p>Kur'an Kütüphanesi</p>
    );
};

export default HomePage;
