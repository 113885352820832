import React, {useEffect} from 'react';

const DownloadPage = () => {
    useEffect(() => {
        // Kullanıcının cihaz türünü kontrol edin
        const userAgent = navigator.userAgent
        // Mobil cihazlarda kullanılan user agent parçalarını kontrol edin
        if (/android/i.test(userAgent)) {
            // Android cihaz ise, kullanıcıyı Google Play sayfasına yönlendirin
            window.location.href = 'https://play.google.com/store/apps/details?id=com.quran.library';
        }
        // Eğer iOS cihaz ise, kullanıcıyı App Store sayfasına yönlendirin
        else if (/iPad|iPhone|iPod/.test(userAgent)) {
            // iOS cihaz ise, kullanıcıyı App Store sayfasına yönlendirin
            window.location.href = 'https://apps.apple.com/us/app/kuran-k%C3%BCt%C3%BCphanesi/id6472725751';
        }

    }, []);

    const containerStyle = {
        display: 'flex',
        justifyContent: 'center', // Görseli yatay olarak ortalar
        marginTop: '50px', // Üstten bir miktar boşluk ekler (isteğe bağlı)
    };

    const imageStyle = {
        width: 'auto',
        height: '83px',
    };

    const iosStyleA = {
        display: "inline-block", overflow: "hidden", borderRadius: "13px", width: "250px", height: "83px"
    }

    const iosStyleImg = {
        borderRadius: "13px", width: "250px", height: "83px"
    }

    const marginStyle = {
        marginLeft: '10px',
        marginRight: '10px',
    };

    return (
        <div style={containerStyle}>

            <div style={marginStyle}>
                <a href='https://play.google.com/store/apps/details?id=com.quran.library&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                    <img
                        alt="Google Play'den alın"
                        src='https://play.google.com/intl/en_us/badges/static/images/badges/tr_badge_web_generic.png'
                        style={imageStyle}
                    />
                </a>
            </div>

            <div style={marginStyle}>
                <a href="https://apps.apple.com/us/app/kuran-k%C3%BCt%C3%BCphanesi/id6472725751?itsct=apps_box_badge&amp;itscg=30200"
                   style={iosStyleA}><img
                    src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/tr-tr?size=250x83&amp;releaseDate=1700956800"
                    alt="Download on the App Store" style={iosStyleImg}/></a>
            </div>


        </div>
    );
};

export default DownloadPage;
